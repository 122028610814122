.fracture-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 15px;
  font-family: 'Open Sans', 'Montserrat', sans-serif; /* Secondary Font */
}

.fracture-table th,
.fracture-table td {
  border: 1px solid #B0BEC5; /* Cool Gray */
  padding: 8px;
  text-align: left;
}

.fracture-table th {
  background-color: #F5F5F5; /* Soft White */
  color: #2E3A45; /* Dark Charcoal */
}

.fracture-table select,
.fracture-table input {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  font-family: 'Open Sans', 'Montserrat', sans-serif; /* Secondary Font */
  font-size: 16px;
  color: #2E3A45; /* Dark Charcoal */
}

.medical-worksheet-form {
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px;
  background-color: #F5F5F5; /* Soft White */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', 'Lato', sans-serif; /* Primary Font */
}

h2, h3 {
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Roboto', 'Lato', sans-serif; /* Primary Font */
  color: #0A3D62; /* Deep Blue */
  font-weight: 700;
}

.form-section {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #FFFFFF; /* White background */
  border: 1px solid #B0BEC5; /* Cool Gray */
  border-radius: 8px;
  display: grid;
  grid-template-columns: repeat(12, 1fr); /* 12-column grid */
  gap: 20px; /* Adjust gap between fields */
}

.col-3 {
  grid-column: span 3; /* Takes up 3 columns */
}

.col-4 {
  grid-column: span 4; /* Takes up 4 columns */
}

.col-6 {
  grid-column: span 6; /* Takes up 6 columns */
}

.col-12 {
  grid-column: span 12; /* Takes up 12 columns */
}

.form-section h3 {
  margin-bottom: 15px;
  border-bottom: 1px solid #B0BEC5; /* Cool Gray */
  padding-bottom: 5px;
  grid-column: span 12;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
  font-family: 'Open Sans', 'Montserrat', sans-serif; /* Secondary Font */
  color: #0A3D62; /* Deep Blue */
}

.form-group input,
.form-group textarea,
.form-group select {
  padding: 10px;
  border: 1px solid #B0BEC5; /* Cool Gray */
  border-radius: 4px;
  font-size: 16px;
  font-family: 'Open Sans', 'Montserrat', sans-serif; /* Secondary Font */
  color: #2E3A45; /* Dark Charcoal */
}

.form-group {
  .note {
    font-size: 12px;
    margin: 10px 0px;
  }
}

button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #17A2B8; /* Teal */
  color: #FFFFFF; /* White */
  border: none;
  border-radius: 4px;
  font-size: 18px;
  font-family: 'Open Sans', 'Montserrat', sans-serif; /* Secondary Font */
  cursor: pointer;
  text-transform: none;
}

button:hover {
  background-color: #5BC0DE; /* Light Teal */
}

input[readonly] {
  background-color: #f0f0f0; /* Light gray background */
  border: 1px solid #B0BEC5; /* Cool Gray */
  color: #888; /* Gray text color */
  cursor: not-allowed; /* Change cursor to indicate non-editable field */
}

.modal-box {
  position: 'absolute';
  top: '50%';
  left: '50%';
  transform: 'translate(-50%, -50%)';
  width: '80%';
  background-color: #FFFFFF; /* White */
  box-shadow: 24px;
  padding: 16px;
}

.modal-button {
  margin-top: 16px;
}