/* General Styles */
.App {
  max-width: 1200px;
  margin: 20px auto;
  padding: 40px 20px;
  font-family: 'Roboto', sans-serif;
}

h4 {
  text-align: center;
  margin-bottom: 40px;
  color: #333;
  font-weight: 500;
}

/* Search Bar */
.search-bar {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.search-row {
  display: flex;
  justify-content: center;
  width: 100%;
}

.search-actions {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.MuiTextField-root {
  width: 100%;
}

.MuiButton-root {
  height: 56px;
  font-weight: 500;
  text-transform: none;
  padding: 0 24px;
  border-radius: 8px;
}

.MuiButton-contained {
  background-color: #1976d2;
  color: white;
}

.MuiButton-contained:hover {
  background-color: #1565c0;
}

.MuiButton-outlined {
  color: #1976d2;
  border: 2px solid #1976d2;
}

.MuiButton-outlined:hover {
  background-color: rgba(25, 118, 210, 0.1);
}

/* Card Grid */
.grid-container {
  margin-top: 30px;
}

.card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
}

.MuiCardContent-root {
  padding: 24px;
}

.MuiTypography-h6 {
  margin-bottom: 10px;
  color: #1976d2;
  font-weight: 600;
}

.MuiTypography-body1 {
  margin: 8px 0;
  color: #555;
  text-transform: capitalize;
}

/* Responsive Design */
@media (max-width: 768px) {
  .search-row {
    flex-direction: column;
  }

  .MuiButton-root {
    width: 100%;
  }

  .MuiGrid-item {
    width: 100%;
  }
}