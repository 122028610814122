.patient-details {
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100vh;
  font-family: 'Roboto', 'Lato', sans-serif; /* Primary Font */
  color: #2E3A45; /* Dark Charcoal */

  .title {
    margin: 10px;
    font-size: 24px;
    font-weight: 700;
    color: #0A3D62; /* Deep Blue */
  }
}

.patient-card {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #FFFFFF; /* White */
  border: 1px solid #B0BEC5; /* Cool Gray */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', 'Lato', sans-serif; /* Primary Font */
}

.studies-title {
  margin: 20px 0;
  font-size: 20px;
  font-weight: 700;
  color: #0A3D62; /* Deep Blue */
  font-family: 'Roboto', 'Lato', sans-serif; /* Primary Font */
}

.studies-grid {
  margin-top: 20px;
}

.study-card {
  transition: transform 0.2s ease-in-out;
  background-color: #FFFFFF; /* White */
  border: 1px solid #B0BEC5; /* Cool Gray */
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.study-card:hover {
  transform: scale(1.05);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}

.patient-name {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
  color: #0A3D62; /* Deep Blue */
  font-family: 'Roboto', 'Lato', sans-serif; /* Primary Font */
}

.report-card {
  transition: transform 0.2s ease-in-out;
  background-color: #FFFFFF; /* White */
  border: 1px solid #B0BEC5; /* Cool Gray */
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}