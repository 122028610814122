.container {
  font-family: Arial, sans-serif;
  text-align: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}

.subtitle {
  color: #555;
  font-size: 14px;
  margin-bottom: 20px;
}

.organization-list {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
}

.organization-item {
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: background-color 0.2s;
}

.organization-item:hover {
  background-color: #f0f0f0;
}

.org-logo {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border-radius: 5px;
  object-fit: cover;
}